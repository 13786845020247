<template>
  <div>
    <v-row no-gutters class="ma-3">
      <v-col class="mr-5">
        <v-checkbox
          v-model="filter_only_active"
          label="Only active"
          hide-details
          @change="updateActiveFilter"
        />
      </v-col>
      <v-col class="mr-5">
        <v-checkbox
          v-model="filter_order_by_name"
          label="Order by name"
          hide-details
          @change="updateOrderByNameFilter"
        />
      </v-col>
      <v-col>
        <v-checkbox
          v-model="filter_include_admins"
          label="Include admins"
          hide-details
          @change="updateIncludeAdminsFilter"
        />
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="getLabelers"
      :items-per-page="getLabelersPerPage"
      hide-default-footer
      :page="getLabelersPage"
      class="elevation-1"
      @click:row="handleClickRow"
      style="cursor: pointer"
      disable-sort
    ></v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        :value="getLabelersPage"
        :length="getLabelersNbPages"
        @input="updatePage"
      ></v-pagination>
      <v-row no-gutters>
        <v-col md="4" offset-md="4">
          <v-text-field
            :value="getLabelersPerPage"
            label="Items per page"
            type="number"
            min="1"
            max="100"
            @change="updateNbLabelersPerPage"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          style="z-index: 100"
          color="pink"
          dark
          absolute
          v-bind="attrs"
          v-on="on"
          top
          right
          fab
          class="mt-10"
          @click="handleClickNewLabeler"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Create new labeler</span>
    </v-tooltip>
    <labeler-details-dialog :active.sync="detailDialog" />
    <labeler-create-dialog :active.sync="createDialog" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import LabelerCreateDialog from "../components/LabelerCreateDialog.vue";
import LabelerDetailsDialog from "../components/LabelerDetailsDialog.vue";

export default {
  components: { LabelerDetailsDialog, LabelerCreateDialog },
  name: "labeler",
  computed: {
    ...mapGetters("labelers", [
      "getLabelers",
      "getLabelersPage",
      "getLabelersPerPage",
      "getLabelersNbPages",
      "getActiveFilter",
      "getOrderByNameFilter",
      "getIncludeAdminsFilter",
    ]),
  },
  data() {
    return {
      headers: [
        {
          text: "Id",
          align: "start",
          value: "id",
        },
        { text: "Email", value: "email_address" },
        { text: "Name", value: "name" },
        { text: "Is Admin", value: "is_admin" },
        { text: "Created At", value: "created_at" },
        { text: "Deactivated At", value: "deactivated_at" },
      ],
      filter_only_active: true,
      filter_order_by_name: true,
      filter_include_admins: true,
      detailDialog: false,
      createDialog: false,
    };
  },
  methods: {
    ...mapActions("labelers", ["retrieveLabelers"]),
    ...mapMutations("labelers", [
      "setLabeler",
      "setLabelersPerPage",
      "setLabelersPage",
      "setActiveFilter",
      "setOrderByNameFilter",
      "setIncludeAdminsFilter",
    ]),
    async retrieveLabelersList() {
      try {
        await this.retrieveLabelers();
        this.error = false;
      } catch (error) {
        this.error = true;
        this.errorMessage = error;
      }
    },
    async updateNbLabelersPerPage(e) {
      e = parseInt(e);
      if (e != "") {
        if (e < 1) {
          e = 1;
        }
        if (e > 100) {
          e = 100;
        }
        this.setLabelersPerPage(e);
        await this.retrieveLabelers();
      }
    },
    async updatePage(e) {
      this.setLabelersPage(e);
      await this.retrieveLabelers();
    },
    async updateActiveFilter(e) {
      this.setLabelersPage(1);
      this.setActiveFilter(e);
      await this.retrieveLabelers();
    },
    async updateOrderByNameFilter(e) {
      this.setLabelersPage(1);
      this.setOrderByNameFilter(e);
      await this.retrieveLabelers();
    },
    async updateIncludeAdminsFilter(e) {
      this.setLabelersPage(1);
      this.setIncludeAdminsFilter(e);
      await this.retrieveLabelers();
    },
    handleClickRow(value) {
      this.setLabeler(value);
      this.detailDialog = true;
    },
    handleClickNewLabeler() {
      this.createDialog = true;
    },
  },
  async created() {
    await this.retrieveLabelers();
  },
};
</script>

<style></style>