<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="800">
      <v-card>
        <v-card-title class="primary white--text">
          Create new labeler
        </v-card-title>

        <v-card-text class="mt-2">
          <v-text-field
            v-model="formData.name"
            label="Name"
            :rules="rules"
            hide-details="auto"
          ></v-text-field>
          <v-text-field
            label="E-mail"
            :rules="rules"
            v-model="formData.email_address"
            hide-details="auto"
          ></v-text-field>
          <v-checkbox v-model="formData.is_admin" label="Admin" hide-details />
        </v-card-text>

        <v-layout justify-center>
          <v-btn color="primary" @click="create"> Create </v-btn>
        </v-layout>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="killDialog"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "App",

  data() {
    return {
      formData: {
        name: "",
        email_address: "",
        is_admin: false,
      },
      rules: [
        (value) => !!value || "Required.",
        (value) => (value && value.length >= 3) || "Min 3 characters",
      ],
    };
  },
  computed: {
    dialog: {
      get: function () {
        return this.active;
      },
      set: function (val) {
        this.$emit("update:active", val);
      },
    },
  },
  props: {
    active: {
      type: Boolean,
      required: true,
      default: () => false,
    },
  },
  methods: {
    ...mapActions("labelers", ["createLabeler"]),
    create: function () {
      this.createLabeler({
        name: this.formData.name,
        email: this.formData.email_address,
        is_admin: this.formData.is_admin,
      });
    },
    killDialog: function () {
      this.dialog = false;
    },
  },

  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
};
</script>

<style>
</style>