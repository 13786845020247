<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="800">
      <v-card>
        <v-card-title class="primary white--text">
          Labeler details
        </v-card-title>

        <v-card-text class="mt-2">
          <div>
            <p class="font-weight-bold d-inline mr-2">ID :</p>
            <p class="d-inline">{{ labeler.id }}</p>
          </div>
          <div>
            <p class="font-weight-bold d-inline mr-2">Name :</p>
            <p class="d-inline">{{ labeler.name }}</p>
          </div>
          <div>
            <p class="font-weight-bold d-inline mr-2">E-mail :</p>
            <p class="d-inline">{{ labeler.email_address }}</p>
          </div>
          <div>
            <p class="font-weight-bold d-inline mr-2">Is Admin :</p>
            <p class="d-inline">{{ labeler.is_admin }}</p>
          </div>
          <div>
            <p class="font-weight-bold d-inline mr-2">Created at :</p>
            <p class="d-inline">{{ labeler.created_at }}</p>
          </div>
          <div v-if="labeler.deactivated_at">
            <p class="font-weight-bold d-inline mr-2">deactivated at :</p>
            <p class="d-inline">{{ labeler.deactivated_at }}</p>
          </div>
        </v-card-text>

        <v-row no-gutters>
          <v-col v-if="labeler.is_admin">
            <v-btn class="ma-3" color="primary" @click="updateRights">
              Remove admin rights
            </v-btn>
          </v-col>
          <v-col v-else>
            <v-btn class="ma-3" color="primary" @click="updateRights">
              Give admin rights
            </v-btn>
          </v-col>
          <v-col v-if="labeler.deactivated_at == null">
            <v-btn class="ma-3" color="primary" @click="deactivate">
              Deactivate
            </v-btn>
          </v-col>
          <v-col v-else>
            <v-btn class="ma-3" color="primary" @click="reactivate">
              Reactivate
            </v-btn>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="killDialog"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "App",

  data() {
    return {};
  },
  computed: {
    ...mapGetters("labelers", ["getLabelers", "getLabeler"]),
    dialog: {
      get: function () {
        return this.active;
      },
      set: function (val) {
        this.$emit("update:active", val);
      },
    },
    labeler: function () {
      let user = this.getLabeler;
      if (user == null) {
        return {
          id: null,
          name: null,
          email_address: null,
          is_admin: null,
          created_at: null,
          deactivated_at: null,
        };
      }
      return user;
    },
  },
  props: {
    active: {
      type: Boolean,
      required: true,
      default: () => false,
    },
  },
  methods: {
    ...mapActions([
      "updateLabelerRights",
      "deactivateLabeler",
      "reactivateLabeler",
      "retrieveLabeler",
    ]),
    killDialog: function () {
      this.dialog = false;
    },
    async updateRights() {
      await this.updateLabelerRights({
        labeler_id: this.labeler.id,
        make_superuser: !this.labeler.is_admin,
      });
    },
    async deactivate() {
      await this.deactivateLabeler(this.labeler.id);
    },
    async reactivate() {
      await this.reactivateLabeler(this.labeler.id);
    },
  },

  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
};
</script>

<style>
</style>